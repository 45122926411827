<template>
  <HomePage
    class="main-content"
    :swipe-img-list="swipeImgList"
    :app-code="APP_CODE"
    :api-hosts="API_HOSTS"
    :download-recommend-tag-img="downloadRecommendTagImg"
    :logo-icon="logoIcon"
  />
</template>

<script>
import HomePage from '@/components/common/home-page/index.vue'
import logoImg from './assets/images/logo.png'
import downloadRecommendTagImg from '@/assets/images/hot_tag_recommend_new.png'
import logoIcon from './assets/images/logo_icon.png'

import { APP_CODE, API_HOSTS } from '../config'

export default {
  components: {
    HomePage,
  },
  data() {
    return {
      logoImg,
      logoIcon,
      downloadRecommendTagImg,
      swipeImgList: [
        { bg: require('./assets/images/momo_bg_1.png') },
        { bg: require('./assets/images/momo_bg_2.png') },
        { bg: require('./assets/images/momo_bg_3.png') },
        { bg: require('./assets/images/momo_bg_4.png') },
      ],
      APP_CODE,
      API_HOSTS,
    }
  },
}
</script>

<style>
@import url('../../../assets/css/minireset.min.css');
@import url('../../../assets/css/common.css');
@import url('../common.css');
</style>

<style scoped>
.main-content ::v-deep .home__bottom--ios {
  bottom: 20px;
}
.main-content ::v-deep .home__bottom--android {
  bottom: 20px;
}
.main-content ::v-deep .home__bottom--pc {
  bottom: 40px;
}
</style>
